import { css } from '@emotion/react'
import Link from 'next/link'
import { colors, font, mq } from 'plume-ui'
import React from 'react'
import { addNewsPicksTrackingParam } from '@/lib/url'
import { newsPicksStartupUrl } from '@/lib/url'

export const NewsPicksStartupMoreLink: React.VFC = () => {
  const href = addNewsPicksTrackingParam(newsPicksStartupUrl)
  return (
    <Link href={href} passHref>
      <a
        className="np-startup-link"
        id="toppage-readmore-link"
        target="_blank"
        rel="noopener noreferrer"
        css={css`
          display: block;
          width: 100%;
          font-size: 14px;
          font-weight: ${font.weight.bold};
          line-height: 100%;
          text-align: center;
          align-items: center;
          border: 1px solid ${colors.black10};
          box-sizing: border-box;
          border-radius: 2px;
          padding: 16px 0;
          letter-spacing: 0.25px;
          transition: all 0.2s ease;
          color: ${colors.black10};
          &:hover {
            background-color: ${colors.black20};
            color: ${colors.white00};
            transition: all 0.2s ease;
          }
          ${mq.untilMedium} {
            padding: 13px 0;
          }
        `}
      >
        <span>もっと見る</span>
      </a>
    </Link>
  )
}
