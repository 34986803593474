import { css } from '@emotion/react'
import { colors, mq, font } from 'plume-ui'
import { ForEnterprise } from '../../common/ForEnterprise'
import { ShowAllLink } from './ShowAllLink'

type TopSectionCardProps = {
  id?: string
  className?: string
  children: React.ReactNode
}

export const TopSectionCard: React.FC<TopSectionCardProps> = ({
  id,
  className,
  children,
}) => {
  return (
    <div
      id={id}
      className={className}
      css={css`
        color: ${colors.black10};
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        overflow: hidden;
        ${mq.untilMedium} {
          box-shadow: none;
        }
      `}
    >
      {children}
    </div>
  )
}

export const TopSectionCardTitle: React.FC<{
  title: string
  subTitle?: string
  showAllUrl?: string
  id?: string
}> = (props) => {
  return (
    <div
      id={props.id}
      css={css`
        font-size: 18px;
        line-height: 100%;
        padding: 24px 24px 8px 0;
        background-color: ${colors.white00};
        ${mq.untilMedium} {
          background-color: transparent;
          padding: 0 4px 8px;
          font-size: 20px;
        }
      `}
    >
      <div
        css={css`
          border-left: 6px solid #0071e3;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 16px;
          ${mq.untilMedium} {
            border-left: none;
            padding-left: 0;
          }
        `}
      >
        <div
          css={css`
            > * {
              vertical-align: middle;
            }
          `}
        >
          {props.subTitle && (
            <div
              css={css`
                color: ${colors.textLight};
                font-size: 14px;
                line-height: 125%;
                padding: 2px 0;
              `}
            >
              {props.subTitle}
            </div>
          )}
          <div
            className="section-title"
            css={css`
              font-size: 20px;
              font-weight: ${font.weight.bold};
              line-height: 1.5;
            `}
          >
            {props.title}
          </div>
        </div>
        {props.showAllUrl && (
          <ForEnterprise>
            <div
              css={css`
                > * {
                  vertical-align: middle;
                }
              `}
            >
              <ShowAllLink
                href={props.showAllUrl}
                css={css`
                  margin-left: 12px;
                `}
              />
            </div>
          </ForEnterprise>
        )}
      </div>
    </div>
  )
}
