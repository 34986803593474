import { css } from '@emotion/react'
import Link from 'next/link'
import { colors, CompanyLogo, font, mq } from 'plume-ui'
import { CompanyData } from '../../../types/api'
import { LineClampText } from '../../atoms/LineClampText'
import { TopSectionCard, TopSectionCardTitle } from './SubSection'

type Props = {
  companies: CompanyData[]
}

export const LatestCompanies: React.VFC<Props> = (props) => {
  return (
    <TopSectionCard id="latest-company-section" className="latest-companies">
      <TopSectionCardTitle
        title="新規登録"
        subTitle="国内スタートアップ"
        showAllUrl="/companies?sort=created&order=DESC&country=JPN"
      />
      <div
        css={css`
          > *:not(:last-child) {
            position: relative;
          }
          > *:not(:last-child)::after {
            content: '';
            position: absolute;
            height: 1px;
            bottom: 0px;
            left: 16px;
            width: calc(100% - 32px);
            background-color: ${colors.gray50};
          }
          ${mq.untilMedium} {
            > *:not(:last-child) {
              margin-bottom: 8px;
            }
            > *:not(:last-child)::after {
              display: none;
            }
          }
        `}
      >
        {props.companies.slice(0, 5).map((company, i) => (
          <LatestCompanyItem key={i} company={company} />
        ))}
      </div>
    </TopSectionCard>
  )
}

type Company = {
  company: CompanyData
}

const LatestCompanyItem: React.VFC<Company> = (props) => {
  return (
    <div
      css={css`
        background-color: ${colors.white00};
        ${mq.untilMedium} {
          border-radius: 8px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
        }
      `}
    >
      <Link href={props.company.path} passHref>
        <a
          className="company-item"
          css={css`
            display: inline-block;
            color: ${colors.black10};
            padding: 16px 24px 24px;
            width: 100%;
            &:hover {
              background-color: rgba(0, 0, 0, 0.03);
            }
            ${mq.untilMedium} {
              padding: 16px;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-bottom: 8px;
              justify-content: space-between;
            `}
          >
            <div
              className="company-name"
              css={css`
                margin-right: 16px;
                flex: 1;
                ${mq.untilMedium} {
                  width: unset;
                }
              `}
            >
              <span
                css={css`
                  font-weight: ${font.weight.bold};
                  font-size: 14px;
                  line-height: 140%;
                  text-decoration: underline solid transparent;
                  transition: text-decoration 0.2s ease 0s;
                  &:hover {
                    text-decoration: underline solid Currentcolor;
                    transition: text-decoration 0.2s ease 0s;
                  }
                  ${mq.untilMedium} {
                    font-size: 15px;
                  }
                `}
              >
                {props.company.name}
              </span>
            </div>
            <CompanyLogo
              src={
                props.company.imageUrl || '/static/images/default/company.png'
              }
              name={props.company.name}
              className="company-logo"
              css={css`
                &:hover {
                  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
                }
              `}
            />
          </div>
          <div
            css={css`
              color: ${colors.black30};
              overflow: hidden;
            `}
          >
            <LineClampText
              className="company-description"
              lineNumber={4}
              height={1.5}
              size={'14px'}
            >
              {props.company.description}
            </LineClampText>
          </div>
        </a>
      </Link>
    </div>
  )
}
