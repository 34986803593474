import { ErrorBoundary } from '../../hoc/ErrorBoundary'
import { FinanceNews } from 'types'
import { css } from '@emotion/react'
import { colors, CompanyLogo, font, mq } from 'plume-ui'
import { TopSectionCard, TopSectionCardTitle } from './SubSection'
import Link from 'next/link'
import { getCategoryTagColor } from '@/lib/colors'
import { FormattedDate } from '../../atoms/FormattedDate'

type Props = {
  financeNews: FinanceNews[]
}

export const DomesticFinanceNewsSection: React.FC<Props> = (props) => {
  return (
    <ErrorBoundary>
      <TopSectionCard id="finance-ma-section" className="domestic-finance-news">
        <TopSectionCardTitle
          title="資金調達ニュース"
          subTitle="国内スタートアップ"
          showAllUrl="/finance_news"
        />
        <div
          css={css`
            > *:not(:last-child) {
              position: relative;
            }
            > *:not(:last-child)::after {
              content: '';
              position: absolute;
              height: 1px;
              bottom: 0px;
              left: 16px;
              width: calc(100% - 32px);
              background-color: ${colors.gray50};
            }
            ${mq.untilMedium} {
              > *:not(:last-child)::after {
                display: none;
              }
              > *:not(:last-child) {
                margin-bottom: 8px;
              }
            }
          `}
        >
          {props.financeNews.slice(0, 6).map((financeNews, index) => (
            <DomesticFinanceNewsItem key={index} financeNews={financeNews} />
          ))}
        </div>
      </TopSectionCard>
    </ErrorBoundary>
  )
}

type FinanceNewsItemProps = {
  financeNews: FinanceNews
}

const DomesticFinanceNewsItem: React.FC<FinanceNewsItemProps> = (props) => {
  return (
    <div
      css={css`
        background-color: ${colors.white00};
        color: ${colors.black30};
        ${mq.untilMedium} {
          border-radius: 8px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
        }
      `}
    >
      <div
        className="news-item"
        css={css`
          display: inline-block;
          padding: 16px 24px 24px;
          width: 100%;
          &:hover {
            background-color: rgba(0, 0, 0, 0.03);
          }
          ${mq.untilMedium} {
            padding: 16px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
          `}
        >
          <div
            css={css`
              margin-right: 16px;
            `}
          >
            <Link href={`${props.financeNews.organization.path}#news`} passHref>
              <a
                className="title"
                css={css`
                  font-weight: ${font.weight.bold};
                  font-size: 14px;
                  line-height: 150%;
                  color: ${colors.black30};
                  margin-bottom: 8px;
                  text-decoration: underline solid transparent;
                  transition: text-decoration 0.2s ease 0s;
                  &:hover {
                    text-decoration: underline solid Currentcolor;
                    transition: text-decoration 0.2s ease 0s;
                  }
                  ${mq.untilMedium} {
                    font-size: 15px;
                  }
                `}
              >
                {props.financeNews.title}
              </a>
            </Link>

            <div
              css={css`
                > *:not(:last-child) {
                  margin-right: 8px;
                }
              `}
            >
              <CategoryTag category={props.financeNews.category} />
              <span
                className="date"
                css={css`
                  font-size: 12px;
                  line-height: 100%;
                  color: ${colors.textLight};
                `}
              >
                <FormattedDate>{props.financeNews.postedDate}</FormattedDate>
              </span>
            </div>
          </div>
          <div>
            <Link href={props.financeNews.organization.path} passHref>
              <a className="item-image">
                <CompanyLogo
                  src={
                    props.financeNews.organization.logoPath ||
                    '/static/images/default/company.png'
                  }
                  name={props.financeNews.organization.name}
                  className="company-logo"
                  css={css`
                    &:hover {
                      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
                    }
                  `}
                />
              </a>
            </Link>
          </div>
        </div>
        <div>
          <Link href={props.financeNews.organization.path} passHref>
            <a
              className="company-name"
              css={css`
                color: ${colors.black10};
                font-size: 14px;
                line-height: 140%;
                text-decoration: underline solid transparent;
                transition: text-decoration 0.2s ease 0s;
                &:hover {
                  text-decoration: underline solid Currentcolor;
                  transition: text-decoration 0.2s ease 0s;
                }
                ${mq.untilMedium} {
                  line-height: 150%;
                }
              `}
            >
              {props.financeNews.organization.name}
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

const CategoryTag: React.FC<{ category: string }> = ({ category }) => {
  const { color, backgroundColor } = getCategoryTagColor(category)
  return (
    <div
      className="category"
      css={css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        color: ${color};
        background-color: ${backgroundColor};
        border-radius: 15px;
        padding: 0 8px;
      `}
    >
      <span
        css={css`
          font-size: 12px;
          line-height: 18px;
          font-weight: ${font.weight.normal};
        `}
      >
        {category}
      </span>
    </div>
  )
}
