export const convertHexColorToRGB = (hex: string) => {
  if (hex.slice(0, 1) == '#') hex = hex.slice(1)
  if (hex.length == 3) {
    hex =
      hex.slice(0, 1) +
      hex.slice(0, 1) +
      hex.slice(1, 2) +
      hex.slice(1, 2) +
      hex.slice(2, 3) +
      hex.slice(2, 3)
  }

  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map((str) => {
    return parseInt(str, 16)
  })
}

export const categoryTagColor = {
  yellow10: '#FFFAEB',
  yellow60: '#D19D00',
  blue10: '#F2F6FA',
  blue60: '#0863BF',
  cyan10: '#F0F6F8',
  cyan60: '#1B8298',
  navy10: '#F2F4FA',
  navy60: '#1F3A8C',
  green10: '#F2FAF3',
  green60: '#2F943C',
  forestGreen10: '#F7FCEB',
  forestGreen60: '#6F9905',
  red10: '#FFF2F2',
  red60: '#db351f',
  brown10: '#F7F3F0',
  brown60: '#704831',
  gray20: '#FAFAFA',
  gray90: '#666',
} as const

export const getCategoryTagColor = (
  category: string
): { color: string; backgroundColor: string } => {
  switch (category) {
    case '資金調達':
      return {
        color: categoryTagColor.yellow60,
        backgroundColor: categoryTagColor.yellow10,
      }
    case '資本提携':
      return {
        color: categoryTagColor.forestGreen60,
        backgroundColor: categoryTagColor.forestGreen10,
      }
    case '資金参加':
      return {
        color: categoryTagColor.brown60,
        backgroundColor: categoryTagColor.brown10,
      }
    case '事業提携':
      return {
        color: categoryTagColor.blue60,
        backgroundColor: categoryTagColor.blue10,
      }
    case '子会社化':
      return {
        color: categoryTagColor.red60,
        backgroundColor: categoryTagColor.red10,
      }
    case '株式譲渡':
      return {
        color: categoryTagColor.navy60,
        backgroundColor: categoryTagColor.navy10,
      }
    case '事業譲渡':
      return {
        color: categoryTagColor.cyan60,
        backgroundColor: categoryTagColor.cyan10,
      }
    case 'ファンド組成':
      return {
        color: categoryTagColor.green60,
        backgroundColor: categoryTagColor.green10,
      }
    default:
      return {
        color: categoryTagColor.gray90,
        backgroundColor: categoryTagColor.gray20,
      }
  }
}
