import { css } from '@emotion/react'
import { GetServerSideProps, NextPage } from 'next'
import Link from 'next/link'
import { colors, font, mq } from 'plume-ui'
import { DefaultHead } from '@/components/common/Head'
import { Template } from '@/components/common/Template'
import { CompanyAccessRanking } from '@/components/pages/top/CompanyAccessRanking'
import { DomesticFinanceNewsSection } from '@/components/pages/top/DomesticFinanceNewsSection'
import { LatestCompanies } from '@/components/pages/top/LatestCompanies'
import { MainSection } from '@/components/pages/top/MainSection'
import { MobileGradationBackground } from '@/components/pages/top/MobileGradationBackground'
import { MobileTopNavBar } from '@/components/pages/top/MobileTopNavBar'
import {
  TopDesktopNotification,
  TopMobileNotification,
} from '@/components/pages/top/Notification'
import { getDailyRanking, getLatestCompanies } from '@/lib/api/company'
import { getFinanceNewsList } from '@/lib/api/financeNews'
import { getNPFeeds } from '@/lib/api/np/feed'
import { fetchLatestNotice } from '@/lib/notice'
import { newsPicksTopUrl, noticePath } from '@/lib/url'
import { FinanceNews, Notice, NPFeed } from '../types'
import { CompanyData } from '../types/api'
import { isEnterpriseWithTrial } from '@/lib/user'
import { useUser } from '../contexts/AuthContext'

type Props = {
  npFeeds: NPFeed[]
  domesticFinanceNewsList: FinanceNews[]
  dailyRankingCompanies: CompanyData[]
  latestCompanies: CompanyData[]
  notice: Notice
}

const GlobalPage: NextPage<Props> = (props) => {
  const {
    domesticFinanceNewsList,
    dailyRankingCompanies,
    latestCompanies,
    notice,
  } = props
  const user = useUser()

  return (
    <Template>
      <DefaultHead />
      <div
        css={css`
          background-color: ${colors.gray40};
          min-height: 100vh;
          margin: auto;
        `}
      >
        {notice && (
          <TopDesktopNotification
            category="お知らせ"
            text={notice.title}
            detailUrl={noticePath(notice.slug || notice.id)}
          />
        )}
        <div
          css={css`
            max-width: 1136px;
            margin: auto;
          `}
        >
          {notice && (
            <TopMobileNotification
              text={notice.title}
              detailUrl={noticePath(notice.slug || notice.id)}
            />
          )}
          <MobileTopNavBar />
          <div
            css={css`
              display: flex;
              > * {
                flex-grow: 0;
                flex-shrink: 0;
              }
              > *:nth-of-type(1) {
                width: 736px;
                margin-right: 48px;
              }
              > *:nth-of-type(2) {
                width: 352px;
              }
              ${mq.untilMedium} {
                flex-direction: column;
                > *:nth-of-type(n) {
                  width: 100%;
                  margin-right: 0;
                }
              }
            `}
          >
            <div id="np-feed">
              <Title />
              <MobileGradationBackground
                css={css`
                  ${mq.untilMedium} {
                    margin-bottom: 24px;
                    padding-bottom: 24px;
                  }
                `}
              >
                <MainSection feeds={props.npFeeds} />
              </MobileGradationBackground>
            </div>
            <div
              css={css`
                padding-top: 70px;
                ${mq.untilMedium} {
                  padding-top: 0px;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  > * {
                    margin-bottom: 32px;
                  }
                  ${mq.untilMedium} {
                    > * {
                      margin-bottom: 24px;
                    }
                  }
                `}
              >
                <MobileGradationBackground
                  css={css`
                    ${mq.untilMedium} {
                      padding: 0 12px 24px;
                    }
                  `}
                >
                  <DomesticFinanceNewsSection
                    financeNews={domesticFinanceNewsList}
                  />
                </MobileGradationBackground>
                <MobileGradationBackground
                  css={css`
                    ${mq.untilMedium} {
                      padding: 0 12px 24px;
                    }
                  `}
                >
                  <CompanyAccessRanking companies={dailyRankingCompanies} />
                </MobileGradationBackground>
                <MobileGradationBackground
                  css={css`
                    ${mq.untilMedium} {
                      padding: 0 12px 24px;
                      margin-bottom: 0;
                    }
                  `}
                >
                  {isEnterpriseWithTrial(user) && (
                    <LatestCompanies companies={latestCompanies} />
                  )}
                </MobileGradationBackground>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}

const Title = () => {
  return (
    <div
      css={css`
        display: flex;
        padding: 24px 0px 10px 0px;
        ${mq.untilMedium} {
          padding: 8px 16px;
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          font-style: normal;
          font-size: 24px;
          line-height: 36px;
          margin-right: 12px;
          ${mq.untilMedium} {
            font-size: 20px;
            line-height: 1.5;
            font-weight: ${font.weight.bold};
          }
        `}
      >
        スタートアップヘッドライン
      </div>
      <div
        className="powered-by-newspicks"
        css={css`
          font-style: italic;
          font-size: 12px;
          line-height: 100%;
          color: ${colors.textLight};
          display: flex;
          align-items: center;
          ${mq.untilMedium} {
            line-height: 1.5;
          }
        `}
      >
        Powered by&nbsp;
        <Link href={newsPicksTopUrl} passHref>
          <a
            css={css`
              color: ${colors.textLight};
              &:hover {
                color: ${colors.link};
              }
            `}
            target="_blank"
            rel="noopener noreferrer"
          >
            NewsPicks
          </a>
        </Link>
      </div>
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  ctx.res.setHeader('Cache-Control', 'public')
  const [
    npFeeds,
    domesticFinanceNewsList,
    dailyRankingCompanies,
    latestCompanies,
    notice,
  ] = await Promise.all([
    getNPFeeds(),
    getFinanceNewsList(),
    getDailyRanking(),
    getLatestCompanies(),
    fetchLatestNotice(),
  ])
  return {
    props: {
      npFeeds,
      domesticFinanceNewsList,
      dailyRankingCompanies,
      latestCompanies,
      notice,
    },
  }
}

export default GlobalPage
